import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  const exp ="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2F16years.png?alt=media&token=a7d7aad5-0062-456d-8d5c-801a17447189";

  const quality = "https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello-01.png?alt=media&token=c375e928-1958-44dd-abe1-c4199ba1c221"

  return (
    <section className={"svgBoxRectanguloBottonRigth"}>
      <div className="pic-wrapper svgHerosection2  w-full h-screen mb-40">
        {BgImages.map((item, index) => {
          return (
            <figure
              key={index}
              className={`pic-${index + 1}`}
              style={{ background: `url("${item}")` }}
            ></figure>
          );
        })}
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-end items-center pb-20">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[35px] md:text-[60px]">
              {title}
            </h1>
            <p className="text-white text-center">{texts}</p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={"three"} />
          </div>
        </div>
      </div>

      <div className="">
        <img src={exp} className="absolute md:w-[500px] w-[200px] md:mt-[-350px] mt-[-250px]" alt=""/>
      </div>
      <div className="mt-10">
        <img src={quality} className="absolute md:w-[500px] w-[200px] md:mt-[-350px] mt-[-250px] right-0" alt=""/>
      </div>
    </section>
  );
}

export default HeroSection;
